@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Quicksand';
  }

  body {
    @apply bg-primary text-primary;
  }

  h1 {
    @apply text-5xl lg:text-6xl font-medium font-mirage;
  }

  h2 {
    @apply text-5xl lg:text-6xl font-medium font-mirage;
  }

  h3 {
    @apply text-xl font-semibold;
  }
}

@layer utilities {
  .v-stack {
    @apply flex flex-col;
  }

  .h-stack {
    @apply flex flex-row;
  }

  .bg-primary {
    @apply bg-gray-50;
  }

  .bg-secondary {
    @apply bg-gray-100;
  }

  .bg-tertiary {
    @apply bg-gray-200;
  }

  .text-primary {
    @apply text-slate-900;
  }

  .text-secondary {
    @apply text-gray-400;
  }

  .font-quicksand {
    font-family: 'Quicksand';
  }

  .font-mirage {
    font-family: 'Mirage';
  }

  .animated {
    @apply transition-all duration-300 ease-in-out;
  }

  .overflow-hidden-webkit {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
}

/* Quicksand */

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url('./Resources/Fonts/Quicksand/Quicksand-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url('./Resources/Fonts/Quicksand/Quicksand-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url('./Resources/Fonts/Quicksand/Quicksand-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url('./Resources/Fonts/Quicksand/Quicksand-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url('./Resources/Fonts/Quicksand/Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url('./Resources/Fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url('./Resources/Fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
  font-weight: 800;
}

/* Mirage */

@font-face {
  font-family: 'Mirage';
  src: local('Mirage'), url('./Resources/Fonts/Mirage/Mirage-Thin.otf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Mirage';
  src: local('Mirage'), url('./Resources/Fonts/Mirage/Mirage-Thin.otf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Mirage';
  src: local('Mirage'), url('./Resources/Fonts/Mirage/Mirage-Regular.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Mirage';
  src: local('Mirage'), url('./Resources/Fonts/Mirage/Mirage-Medium.otf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Mirage';
  src: local('Mirage'), url('./Resources/Fonts/Mirage/Mirage-Medium.otf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Mirage';
  src: local('Mirage'), url('./Resources/Fonts/Mirage/Mirage-Bold.otf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Mirage';
  src: local('Mirage'), url('./Resources/Fonts/Mirage/Mirage-Black.otf') format('truetype');
  font-weight: 800;
}
